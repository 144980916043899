import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterView from "@/views/RegisterView.vue";
import AdminUserSettingsView from "@/views/AdminUserSettingsView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/register",
		name: "register",
		component: RegisterView,
	},
	// {
	// 	path: "/admin_settings",
	// 	name: "admin settings",
	// 	component: AdminUserSettingsView,
	// },
	{
		path: "/register/:hash",
		name: "instance settings",
		component: AdminUserSettingsView,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
