<template>
	<div class="prose w-screen h-screen p-2">
		<div class="w-screen">
			<h1>Register</h1>
		</div>
		<!-- {{ currentObj }} -->
		<div class="w-screen flex flex-col justify-center">
			<div class="flex flex-row justify-center">
				<div class="flex flex-col justify-center">
					<h2>Leave your email here</h2>
					<h6 class="text-gray-500 text-sm pb-2">We'll send you a link for feather steps.</h6>
				</div>
			</div>
			<div class="flex flex-row justify-center gap-2 w-full">
				<div class="sm:col-span-4 flex flex-col justify-center w-72">
					<!-- <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label> -->
					<div class="mt-2-">
						<input
							id="email"
							name="email"
							type="email"
							placeholder="email"
							autocomplete="email"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							v-model="email"
						/>
					</div>
				</div>
				<div class="mt-6- flex flex-col justify-center">
					<!-- <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> -->
					<button
						type="submit"
						class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						@click="onRegister"
					>
						Register
					</button>
				</div>
				<!-- <input type="email" v-model="currentObj.email" /> -->
				<!-- <input type="button" @click="onRegister" value="Register" /> -->
			</div>
		</div>
	</div>
</template>
<script setup>
const email = defineModel("email", { type: String });
const events = defineEmits(["onRegister"]);

const onRegister = async () => {
	events("onRegister", email);
};
</script>
