import axios from "axios";
import router from "@/router";
// import { refreshTokenAsync } from "./auth/auth_api";
// import { useAuthStore } from "@/stores/auth_store";

let config = {
	baseURL: "/",
	timeout: 10000,
	withCredentials: true,
};

const instance = axios.create(config);

// prevent of caching responses
instance.defaults.headers = {
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

// Request interceptor
instance.interceptors.request.use((config) => {
	// debugger
	// Modify the request config here
	// return config

	const token = localStorage.getItem("user_token");
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	// config.headers.CacheControl = "no-store";
	// 'Cache-Control': 'no-store'

	return config;
});

// Response interceptor
instance.interceptors.response.use(
	(response) => {
		// Handle the response here
		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401) {
				// Redirect to login page
				//
				router.push("/signin");
				//
			} else if (error.response.status === 409) {
				console.log("duplicated item");
			} else if (error.response.status === 400) {
				console.log("hql syntax error:");
				console.log(error.response);
			} else if (error.response.status === 404) {
				console.log("nothing have found");
			} else {
				// Show a generic error message
				// alert({
				//   message: "An error occurred. Please try again later.",
				//   err: error,
				// });
				alert(error);
			}
		}
		return Promise.reject(error);
	},
);

export default instance;
