import instance from "../axios";

export const getRegistrationPage = function (limit, offset, name, on_response) {
	const request_data = {
		limit: limit,
		offset: offset,
	};

	if (name) {
		request_data.name = name;
		request_data.name_filter = true;
	}

	// debugger
	// http://localhost:8080/v1/issues?limit=100&offset=0
	instance.get("/v1/registrations", { params: request_data }).then((response) => {
		on_response(response.data);

		console.log("response.data");
		console.log(response.data);
	});
};

export const getRegistrationByID = function (id, on_response) {
	// debugger
	// http://localhost:8080/v1/issues?limit=100&offset=0
	instance.get("/v1/registrations" + "/" + id).then((response) => {
		on_response(response.data);

		console.log("response.data");
		console.log(response.data);
	});
};

export const getRegistrationByIDAsync = async function (id) {
	try {
		const result = await instance.get("/v1/registrations" + "/" + id);
		// debugger;
		return result.data.registration;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const getRegistrationByEmailOrDomainAsync = async function (email) {
	try {
		const result = await instance.get("/v1/registrations" + "/" + email);
		// debugger;
		return result.data.registration;
	} catch (error) {
		if (error.response.status === 404) {
			return null;
		} else {
			// console.error("Error fetching data:", error);
			throw error;
		}
	}
};

export const getRegistrationByEmailOrDomain = function (emailOrDomain, on_response, on_error) {
	// debugger
	// http://localhost:8080/v1/issues?limit=100&offset=0
	instance
		.get("/v1/registrations" + "/" + emailOrDomain)
		.then((response) => {
			on_response(response.data);

			console.log("response.data");
			console.log(response.data);
		})
		.catch((error) => {
			if (error.response.status === 404) {
				on_response(null);
			} else {
				on_error(error);
				// console.error("Error fetching data:", error);
				// throw error;
			}
		});

	// try {
	// 	const result = instance.get("/v1/registrations" + "/" + email);
	// 	// debugger;
	// 	return result.data.registration;
	// } catch (error) {
	// 	if (error.response.status === 404) {
	// 		return null;
	// 	} else {
	// 		// console.error("Error fetching data:", error);
	// 		throw error;
	// 	}
	// }
};

export const updateRegistrationAsync = async function (newRegistration) {
	try {
		const result = await instance.put("/v1/registrations" + "/" + newRegistration.id, newRegistration);
		// debugger;
		return result.data.registration;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const createRegistrationAsync = async function (newRegistration) {
	try {
		const result = await instance.post("/v1/registrations", newRegistration);
		// debugger;
		return result.data.registration;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const deleteRegistrationAsync = async function (registrationId) {
	try {
		// debugger;
		const result = await instance.delete("/v1/registrations" + "/" + registrationId);
		// debugger;
		return result.data;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};
