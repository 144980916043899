<template>
	<div class="prose w-screen p-2">
		<div v-if="currentObj.showEmailInput">
			<Register v-model:email="currentObj.email" v-on:onRegister="onRegister" />
		</div>
		<div v-if="currentObj.showOk">
			<RegisterOk />
		</div>
		<div v-if="currentObj.showFalse">
			<RegisterFalse :err-message="currentObj.falseMessage" />
		</div>
	</div>
</template>
<script setup>
import { createEmailCheckAsync } from "@/api/email_check/email_check_api";
import { reactive } from "vue";
import Register from "./Register.vue";
import RegisterOk from "./RegisterOk.vue";
import RegisterFalse from "./RegisterFalse.vue";

const currentObj = reactive({
	email: "",
	alreadyIUse: false,
	showEmailInput: true,
	showOk: false,
	showFalse: false,
	falseMessage: "",
});

const onRegister = async () => {
	console.log("onRegister");
	const newEmailCheck = { email: currentObj.email };
	try {
		const result = await createEmailCheckAsync(newEmailCheck);
		console.log(result);
		currentObj.showEmailInput = false;
		currentObj.showOk = true;
	} catch (error) {
		if (error.response.status === 409) {
			currentObj.alreadyIUse = true;
			console.log("duplicated item");
			currentObj.falseMessage = "duplicated item";
			currentObj.showEmailInput = false;
			currentObj.showFalse = true;
		}
	}
	// router.push("/admin_settings");
};
</script>
