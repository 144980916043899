<template>
	<div>
		<div>
			<h1>Bad news</h1>
		</div>
		<div class="w-screen">
			<div class="flex flex-row justify-center">
				<h2>Probably something went wrong.</h2>
			</div>
		</div>
	</div>
</template>
