<template>
	<div>
		<div>
			<h1>Instance settings are OK</h1>
		</div>
		<div class="w-screen">
			<div class="flex flex-row justify-center">
				<h2>Check your email in a while!</h2>
			</div>
		</div>
	</div>
</template>
