import instance from "../axios";

export const getRegistrationPage = function (limit, offset, name, on_response) {
	const request_data = {
		limit: limit,
		offset: offset,
	};

	if (name) {
		request_data.name = name;
		request_data.name_filter = true;
	}

	// debugger
	// http://localhost:8080/v1/issues?limit=100&offset=0
	instance.get("/v1/registrations", { params: request_data }).then((response) => {
		on_response(response.data);

		console.log("response.data");
		console.log(response.data);
	});
};

export const getCheckEmailByEmailHash = function (id, on_response) {
	// debugger
	// http://localhost:8080/v1/issues?limit=100&offset=0
	instance.get("/v1/email_checks" + "/" + id).then((response) => {
		on_response(response.data);

		console.log("response.data");
		console.log(response.data);
	});
};

export const getCheckEmailByEmailAsync = async function (id) {
	try {
		const result = await instance.get("/v1/email_checks" + "/" + id);
		// debugger;
		return result.data.email_check;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const updateEmailCheckAsync = async function (newEmailCheck) {
	try {
		const result = await instance.put("/v1/email_checks" + "/" + newEmailCheck.email, newEmailCheck);
		// debugger;
		return result.data.email_check;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const createEmailCheckAsync = async function (newEmailCheck) {
	try {
		const result = await instance.post("/v1/email_checks", newEmailCheck);
		// debugger;
		return result.data.email_check;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};

export const deleteEmaiCheckAsync = async function (id) {
	try {
		// debugger;
		const result = await instance.delete("/v1/email_checks" + "/" + id);
		// debugger;
		return result.data;
	} catch (error) {
		// console.error("Error fetching data:", error);
		throw error;
	}
};
